<script>
import appConfig from "@/app.config";
import { integer } from "@vuelidate/validators";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  props: {
    list: [],
    currentPage: integer,
    nextSoal: Function,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull: process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      idJawabanPetunjuk: 0,
      link: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    jawabanPetunjuk(id) {
      this.idJawabanPetunjuk = id;
    },
    mengerjakan() {
      this.nextSoal(0);
    },
  },
};
</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="container-fluid">
    <!-- <HeaderAsesmenHC /> -->
    <div class="card">
      <div class="card-header bg-primary text-white font-size-16" style="border-top-right-radius: 5px; border-top-left-radius: 5px">Petunjuk Pilihan Ganda</div>
      <div class="card-body font-size-14" style="border-bottom-right-radius: 5px; border-bottom-left-radius: 5px">
        <p>Silahkan pilih salah satu jawaban dengan klik pada pilihan jawaban yang tersedia.</p>
        <div><strong>Contoh Soal</strong></div>
        <div class="bg-light">
          <p class="p-2 mb-0">
            {{ list.soal_text }}
          </p>
        </div>
        <img v-if="list.soal_image" :src="link + list.soal_image" style="max-width: 70vw" class="mx-auto mb-2" />
        <div class="soal mt-3 px-3">1. {{ list.pertanyaan }}</div>
        <div class="pilihan-ganda row mx-4">
          <div class="d-flex">
            <button
              class="mt-3 btn btn-block flex-grow-1"
              style="width: 200px"
              v-for="jawaban in list.jawaban"
              :key="jawaban.idjawaban"
              type="button"
              :class="['btn', 'mx-2', idJawabanPetunjuk === jawaban.idjawaban ? 'btn-primary' : 'btn-secondary']"
              @click="jawabanPetunjuk(jawaban.idjawaban)"
            >
              {{ jawaban.jawaban }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <p class="font-size-14 mb-0">
      Jika anda sudah memahami petunjuk, silahkan tekan tombol
      <strong>MULAI MENGERJAKAN</strong>
    </p>
    <hr class="mt-2 mb-2" />
    <div class="text-center mb-2">
      <button type="button" class="btn btn-success" @click="mengerjakan()">MULAI MENGERJAKAN</button>
    </div>
  </div>
  <!-- end row -->
  <!-- </Layout> -->
</template>
<style scoped>
.border-none {
  border-style: hidden;
}
</style>
