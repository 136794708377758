<script>
import appConfig from "@/app.config";
import { integer } from "@vuelidate/validators";
import $ from "jquery";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  computed: {
    shuffledJawaban() {
      // Shuffle the array of jawaban
      return this.shuffleArray(this.list.jawaban);
    },
  },
  components: {},
  data() {
    return {
      link: process.env.VUE_APP_BACKEND_URL,
    };
  },
  props: {
    list: [],
    nomorUrut: integer,
    currentPage: integer,
    selectAnswer: Function,
  },
  emits: ["showImage"],
  mounted() {
    const self = this;
    $("img").click(function () {
      console.log($(this).attr("src"));
      self.$emit("showImage", $(this).attr("src"));
    });
  },
  methods: {
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;

        // Simpan indeks jawaban yang benar jika ditemukan
        if (temporaryValue.jawaban_benar) {
          this.indexOfCorrectAnswer = randomIndex;
        }
      }

      return array;
    },
    selectAnswerFunction(list, jawaban) {
      if (typeof this.selectAnswer === "function") {
        this.selectAnswer(list, jawaban);
      }
    },
  },
};
</script>

<template>
  <img
    v-if="list.soal_image"
    :src="link + list.soal_image"
    style="max-width: 70vw"
    class="mx-auto mb-2"
  />
  <div v-if="list.soal_text !== null" class="card mb-2">
    <div class="card-body bg-light" style="border-radius: 5px">
      {{ list.soal_text }}
    </div>
  </div>
  <div class="soal mt-3 px-3">{{ nomorUrut }}. {{ list.pertanyaan }}</div>
  <div class="pilihan-ganda row mx-4">
    <!-- <div
      class="col-md-6"
      v-for="jawaban in list.jawaban"
      :key="jawaban.idjawaban"
    >
      <div class="d-flex">
        <button
          class="mt-3 btn btn-block flex-grow-1"
          :class="[jawaban.selected ? 'btn-primary' : 'btn-secondary']"
          @click="selectAnswerFunction(list, jawaban)"
        >
          {{ jawaban.jawaban }}
        </button>
      </div>
    </div> -->

    <div
      class="col-md-6 mt-5"
      v-for="jawaban in shuffledJawaban"
      :key="jawaban.idjawaban"
    >
      <div
        class="d-flex justify-content-center align-items-center pilihan-jawaban"
        :class="[jawaban.selected ? 'bg-primary' : 'bg-secondary']"
        @click="selectAnswerFunction(list, jawaban)"
      >
        <div v-html="jawaban.jawaban"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.border-none {
  border-style: hidden;
}

.selected {
  background-color: #00f;
  /* Change the background color for selected buttons */
  color: #fff;
  /* Change the text color for selected buttons */
}
.pilihan-jawaban {
  height: 150px;
  color: #fff;
  text-align: center;
}
</style>

<style>
.pilihan-jawaban img {
  text-align: center;
  height: 80% !important;
  max-height: 100px;
}
</style>
