<script>
import appConfig from "@/app.config";
import { integer } from "@vuelidate/validators";
import Editor from "@tinymce/tinymce-vue";
import modalHistoryJawaban from "./modal_history_jawaban.vue";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Editor,
    modalHistoryJawaban,
  },
  props: {
    list: [],
    nomorUrut: integer,
    currentPage: integer,
    selectAnswer: Function,
    idJadwalPeserta: String,
    idJadwal: String,
  },
  data() {
    return {
      contentTinymce: "",
      editorConfig: {
        // TinyMCE configuration options
        menubar: false, // Show the menu bar
        height: 280,
        resize: false,
        toolbar:
          "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons",
        branding: false, // Hide the TinyMCE branding
      },
      block1W: "50%",
      activeTab: "tab1",
      tabs: [
        { id: "tab1", title: "Petunjuk" },
        { id: "tab2", title: "Soal" },
      ],
      modalHistoryJawaban: false,
      refreshTable: 0,
      link: process.env.VUE_APP_BACKEND_URL,
      soalMaximize: false,
      jawabanMaximize: false,
    };
  },
  mounted() {
    console.log(this.nomorUrut);
    this.contentTinymce = this.list[0]?.jawaban_peserta?.jawaban ?? '';
  },
  methods: {
    selectAnswerFunction(list, jawaban) {
      if (typeof this.selectAnswer === "function") {
        this.selectAnswer(list, jawaban);
      }
    },
    saveAnswers() {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      var config_store_data = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "peserta/jadwal-peserta/simpan-jawaban-text",
        data: {
          jawaban: this.contentTinymce,
          id_soal: this.list[0].idsoal,
          id_jadwal: this.idJadwal,
          id_jadwal_peserta: this.idJadwalPeserta,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      };
      console.log(this.contentTinymce);
      axios(config_store_data)
        .then(function (response) {
          // Handle respon dari API jika diperlukan
          console.log("Jawaban berhasil disimpan:", response.data);
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Jawaban berhasil disimpan!",
            text: "Jawaban berhasil disimpan di server.",
          });
        })
        .catch(function (error) {
          // Handle error jika terjadi
          Swal.close();
          console.error("Error saat menyimpan jawaban:", error);
          this.axiosCatchError = error.response.data.data; // Tangani error dan simpan pesan ke objek komponen Vue
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat menyimpan jawaban.",
          });
        });
      this.refreshTable++;
    },
    drag: function (e) {
      let dragX = e.clientX;
      let block = this.$refs.block1;
      document.onmousemove = function onMouseMove(e) {
        block.style.width = block.offsetWidth + e.clientX - dragX + "px";
        dragX = e.clientX;
      };
      // remove mouse-move listener on mouse-up
      document.onmouseup = () =>
        (document.onmousemove = document.onmouseup = null);
    },
    switchTab(tabName) {
      this.activeTab = tabName;
    },
    handleJawabanSelected(data) {
      // Update the modal content using the received data
      this.contentTinymce = data.jawaban; // Assuming you have a `content` property for the modal content
      console.log(this.contentTinymce);
      this.modalHistoryJawaban = false;
    },
    minimize: function (window) {
      if (window == "soal") {
        $(".card-soal").removeClass("col-12");
        $(".card-soal").removeClass("order-1");
        $(".card-soal").addClass("col-6");
        $(".card-soal").addClass("order-2");
        this.soalMaximize = false;
      } else {
        $(".card-jawaban").removeClass("col-12");
        $(".card-jawaban").removeClass("order-1");
        $(".card-jawaban").addClass("col-6");
        $(".card-jawaban").addClass("order-2");
        this.jawabanMaximize = false;
      }
    },
    maximize: function (window) {
      if (window == "soal") {
        $(".card-soal").removeClass("col-6");
        $(".card-soal").removeClass("order-2");
        $(".card-soal").addClass("col-12");
        $(".card-soal").addClass("order-1");
        this.soalMaximize = true;
      } else {
        $(".card-jawaban").removeClass("col-6");
        $(".card-jawaban").removeClass("order-2");
        $(".card-jawaban").addClass("col-12");
        $(".card-jawaban").addClass("order-1");
        this.jawabanMaximize = true;
      }
    },
  },
  beforeUnmount() {},
};
</script>

<template>
  <!--  <img-->
  <!--    v-if="list[0].soal_image"-->
  <!--    :src="link + list[0].soal_image"-->
  <!--    style="max-width: 70vw"-->
  <!--    class="mx-auto mb-2"-->
  <!--  />-->
  <div class="row">
    <div class="card col-6 order-2 card-soal">
      <div
        class="card-header bg-primary text-white d-flex justify-content-between"
      >
        <h5 class="card-title text-white">Soal</h5>
        <div class="d-flex gap-3">
          <button
            class="btn btn-sm text-white"
            title="Minimizes"
            v-if="soalMaximize"
            @click="minimize('soal')"
          >
            <i class="mdi mdi-window-minimize font-size-14"></i>
          </button>
          <button
            class="btn btn-sm text-white"
            title="Maximizes"
            v-if="!soalMaximize"
            @click="maximize('soal')"
          >
            <i class="mdi mdi-window-maximize font-size-14"></i>
          </button>
        </div>
      </div>
      <div class="card-body px-0 pt-0" style="height: 500px; overflow-y: auto">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Studi Kasus</div>
          </div>
          <div class="card-body">
            {{ list[0].soal_text }}
            <div class="w-100 text-center">
              <img
                v-if="list[0].soal_image"
                :src="link + list[0].soal_image"
                style="max-width: 70vw"
                class="mx-auto mb-2"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title">Pertanyaan</div>
          </div>
          <div class="card-body">
            {{ list[0].pertanyaan }}
          </div>
        </div>
      </div>
    </div>
    <div class="card col-6 order-2 card-jawaban">
      <div
        class="card-header bg-primary text-white d-flex justify-content-between"
      >
        <h5 class="card-title text-white">Jawaban</h5>
        <div class="d-flex gap-3">
          <button
            class="btn btn-sm text-white"
            title="Minimizes"
            v-if="jawabanMaximize"
            @click="minimize('jawawaban')"
          >
            <i class="mdi mdi-window-minimize font-size-14"></i>
          </button>
          <button
            class="btn btn-sm text-white"
            title="Maximizes"
            v-if="!jawabanMaximize"
            @click="maximize('jawaban')"
          >
            <i class="mdi mdi-window-maximize font-size-14"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <Editor
          api-key="wdlv4g0lb9v38bllo9fgy6yxvuo4wnhauak1zpfjfsub4qbl"
          :init="editorConfig"
          v-model="contentTinymce"
          ref="editor"
        ></Editor>
      </div>
      <div
        class="card-footer"
        style="justify-content: space-between; display: flex"
      >
        <button
          type="button"
          class="btn btn-secondary m-2"
          v-on:click="modalHistoryJawaban = !modalHistoryJawaban"
          style="width: 150px"
        >
          History Jawaban
        </button>
        <button
          type="button"
          class="btn btn-success m-2"
          style="width: 150px"
          @click="saveAnswers()"
        >
          Simpan Jawaban
        </button>
      </div>
    </div>
  </div>
  <b-modal
    v-model="modalHistoryJawaban"
    hide-footer
    title="List History Jawaban"
    dialog-class="modal-xl"
    ref="modalHistoryJawaban"
  >
    <modalHistoryJawaban
      @jawaban-selected="handleJawabanSelected"
      :idJadwal="idJadwal"
      :idJadwalPeserta="idJadwalPeserta"
      :idsoal="list[0].idsoal"
      :refreshTable="modalHistoryJawaban"
    />
  </b-modal>
</template>

<style scoped>
.border-none {
  border-style: hidden;
}

.outer {
  display: flex;
  flex-direction: row;
}

.block {
  height: 100px;
  width: 50%;
  /* 50% would suffice*/
}

.block-2 {
  flex: 1;
  /* adjust automatically */
  min-width: 0;
  /* allow flexing beyond auto width */
  overflow: hidden;
  /* hide overflow on small width */
  min-height: 80vh;
}

.slider {
  line-height: 100%;
  width: 10px;
  background-color: #dee2e6;
  border: none;
  cursor: col-resize;
  user-select: none;
  /* disable selection */
  text-align: center;
}
</style>
